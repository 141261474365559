import React, { useRef, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { login } from '../service/service';
const Login = () => {
    let navigate = useNavigate();
    const [userName, setuserName] = React.useState("")
    const [passWord, setpassWord] = React.useState("")
    const loginFun = () => {
        let data = {
            "userName": userName,
            "password": passWord
        }
        login(data).then(result => {
            


        })

    }
    return (
        <div>
           <div id="login">
                <h3 class="text-center text-white pt-5">Login form</h3>
                <div class="container">
                    <div id="login-row" class="row justify-content-center align-items-center">
                        <div id="login-column" class="col-md-6">
                            <div id="login-box" class="col-md-12">
                                <div id="login-form" class="form" >
                                    <h3 class="text-center text-info">Login</h3>
                                    <div class="form-group">
                                        <label for="username" class="text-info">Username:</label><br/>
                                        <input type="text" name="username" id="username" class="form-control" onChange={(e) => setuserName(e.target.value)}/>
                                    </div>
                                    <div class="form-group">
                                        <label for="password" class="text-info">Password:</label><br/>
                                        <input type="text" name="password" id="password" class="form-control" onChange={(e) => setpassWord(e.target.value)}/>
                                    </div>
                                    <div class="form-group">
                                        <label for="remember-me" class="text-info"><span>Remember me</span> <span>
                                            <input id="remember-me" name="remember-me" type="checkbox" /></span></label><br/>
                                        <input type="submit" name="submit" class="btn btn-info btn-md" value="submit" onClick={loginFun}/>
                                    </div>
                                    {/* <div id="register-link" class="text-right">
                                        <a href="#" class="text-info">Register here</a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login