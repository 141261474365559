import BasicFilterDemo from "./datatables";
const Chinacommodity = () => {
    
    return (
      <div>
        <div class="psectortitle row">
          <div className="col-sm-9">
            <h1>China Commodity Price Tracker</h1>
            <nav>
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a>Home</a>
                </li>
                <li class="breadcrumb-item active">China Commodity Price Tracker</li>
              </ol>
            </nav>
          </div>
  
       
        </div>
        <section class="section dashboard">
          <div class="row">
            
            <div class="col-lg-12">
              <div class="col-12">
               <BasicFilterDemo></BasicFilterDemo>
              </div>
            </div>
          
          </div>
        </section>
      </div>
    );
  };
  export default Chinacommodity;