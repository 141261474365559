var CryptoJS = require("crypto-js");
export function getUserDetails() {

    if (localStorage.getItem('stockuser')) {
        var data = JSON.parse(localStorage.getItem('stockuser'))
        return data
    } else {
        return null
    }
}
export function GetDecrypt(data) {
    var key = 'wealth_@12345678'//key used in Python
    key = CryptoJS.enc.Utf8.parse(key);
    var decrypted = CryptoJS.AES.decrypt(data, key, { mode: CryptoJS.mode.ECB });
    // var bytes = CryptoJS.AES.decrypt(data, 'wealth_@12345678');
    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
}