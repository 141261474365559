import axios from "axios";
import { BASE_URL } from "../config/app_url";
import { GetDecrypt } from "./common";
const baseURL=BASE_URL
const decrypt = true;
axios.interceptors.response.use((response) => {
    if(decrypt){
            var res = GetDecrypt(response.data)
            response.data = res
            // console.log(response.data)
    }
    
    return response
})
export function getActionType(data) {
    return axios.post(baseURL + '/list/getActionType', data);
}
export function getInstrumentType(data) {
    return axios.post(baseURL + '/list/getInstrumentType', data);
}
export function getTransactionBy(data) {
    return axios.post(baseURL + '/list/getTransactionBy', data);
}
export function getBuySellType(data) {
    return axios.post(baseURL + '/list/getBuySellType', data);
}
export function getNewsKeyword(data) {
    return axios.post(baseURL + '/list/getNewsKeyword', data);
}
export function getInsiderBuySell(data) {
    return axios.post(baseURL + '/list/getInsiderBuySell', data);
}
export function getPublicAnnouncements(data) {
    return axios.post(baseURL + '/list/getPublicAnnouncements', data);
}
export function getLatestEarning(data) {
    return axios.post(baseURL + '/list/getLatestEarning', data);
}
export function getBusinessNews(data) {
    return axios.post(baseURL + '/list/getBusinessNews', data);
}
export function getAllCompany(data) {
    return axios.post(baseURL + '/list/getAllCompany', data);
}

export function allInsiderCount(data) {
    return axios.post(baseURL + '/list/allInsiderCount', data);
}
export function buySellAmount(data) {
    return axios.post(baseURL + '/list/buySellAmount', data);
}
export function getStockPriceInvestor(data) {
    return axios.post(baseURL + '/list/getStockPriceInvestor', data);
}
export function getInsiderBulkCombinedTable(data) {
    return axios.post(baseURL + '/list/getInsiderBulkCombinedTable', data);
}
export function getColorLineChart(data) {
    return axios.post(baseURL + '/list/getColorLineChart', data);
}
export function getHitMapChart(data) {
    return axios.post(baseURL + '/list/getHitMapChart', data);
}

export function sign_up(data) {
    return axios.post(baseURL + '/user/sign_up', data);
}
export function login(data) {
    return axios.post(baseURL + '/user/login', data);
}
export function getallUserDetails(data) {
    return axios.post(baseURL + '/user/getUserDetails', data);
}
export function updateUserDetails(data) {
    return axios.post(baseURL + '/user/updateUserDetails', data);
}

export function stockGetInsiderBuySell(data) {
    return axios.post(baseURL + '/mystock/getInsiderBuySell', data);
}
export function stockGetPublicAnnouncements(data) {
    return axios.post(baseURL + '/mystock/getPublicAnnouncements', data);
}
export function stockGetLatestEarning(data) {
    return axios.post(baseURL + '/mystock/getLatestEarning', data);
}
export function stockGetBusinessNews(data) {
    return axios.post(baseURL + '/mystock/getBusinessNews', data);
}