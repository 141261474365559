import React, { useRef, useLayoutEffect, useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5hierarchy from '@amcharts/amcharts5/hierarchy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { buySellAmount } from '../service/service';

function EchartsTree() {
  const id = 'chartdiv1';
  const [treeData, settreeData] = React.useState({});
  const objGroup = function (data) {

    let company = {}; 
    for (let arr of data) { 
      const sector = arr.sector;
      const name = arr.nse_name;
      if (sector in company) { 
        company[sector][name] = Number(arr.net_value)
      }
      else { 
        company[sector] = { [name]: Number(arr.net_value) };
      }
    }
    return company;

  };
  const buySellAmountFun = () => {
    let data = {
      "allCompany": ''
    }
    buySellAmount(data).then(result => {
      if (result.data.success) {
        var response = result.data.response
        var newData = {}
        var array = []
        for (let arr of response) {
          var obj = { x: arr.sector, y: (arr.net_value).toLocaleString("en-IN", { maximumFractionDigits: 0 }) }
          array.push(obj)
        }
        var newData = objGroup(response)
        settreeData(newData)

      }

    })

  }
  useLayoutEffect(() => {
    const root = am5.Root.new(id);
    const myTheme = am5.Theme.new(root);
    
    myTheme
      .rule('RoundedRectangle', ['hierarchy', 'node', 'shape', 'depth1'])
      .setAll({
        strokeWidth: 1,
      });

    myTheme
      .rule('RoundedRectangle', ['hierarchy', 'node', 'shape', 'depth2'])
      .setAll({
        fillOpacity: 1,
        strokeWidth: 1,
        strokeOpacity: 1,
      });

    myTheme.rule('Label', ['node', 'depth1']).setAll({
      fontSize: 20,
      fontWeight: "bold",
      text: "{category}: [bold]{sum}[/]"
    });

    myTheme.rule('Label', ['node', 'depth2']).setAll({
      fontSize: 15,
      fontWeight: "bold",
      text: "{category}: [bold]{sum}[/]"
      });

    root.setThemes([am5themes_Animated.new(root), myTheme]);

    // Create wrapper container
    var container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout,
        
      })
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/hierarchy/#Adding
    var series = container.children.push(
      am5hierarchy.Treemap.new(root, {
        sort: 'descending',
        singleBranchOnly: false,
        downDepth: 1,
        upDepth: 0,
        initialDepth: 1,
        valueField: 'value',
        categoryField: 'name',
        childDataField: 'children',
        layoutAlgorithm: "squarify",
        nodePaddingOuter: 0,
        nodePaddingInner: 0,
      })
    );

    series.get('colors').set('step', 1);

    container.children.moveValue(
      am5hierarchy.BreadcrumbBar.new(root, {
        series: series,
      }),
      0
    );

    var data = treeData

    function processData(data) {
      var treeData = [];

      var smallBrands = { name: 'Other', children: [] };

      am5.object.eachOrdered(
        data,
        (brand) => {
          var brandData = { name: brand, children: [] };
          var brandTotal = 0;
          for (var model in data[brand]) {

            brandTotal += data[brand][model];
          }

          for (var model in data[brand]) {
            brandData.children.push({
              name: model,
              value: data[brand][model],
            });
            
          }
          if (brandTotal > 0) {
            treeData.push(brandData);
          }
        },
        (a, b) => {
          let aval = 0;
          let bval = 0;
          am5.object.each(data[a], (key, val) => (aval += val));
          am5.object.each(data[b], (key, val) => (bval += val));
          if (aval > bval) return -1;
          if (aval < bval) return 1;
          return 0;
        }
      );

      return [
        {
          name: 'Home',
          children: treeData,
        },
      ];
    }

    series.data.setAll(processData(data));
    series.set('selectedDataItem', series.dataItems[0]);
    
    return () => {
      root.dispose();
    };
  }, [treeData]);

  useEffect(() => {
    buySellAmountFun()

  }, [])

  return <div id={id} style={{ width: '100%', height: '400px' }}></div>;
  
}

export default EchartsTree