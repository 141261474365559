
import React, { useRef, useEffect } from "react";
import $ from "jquery";
import {
  getColorLineChart,
} from "../service/service";
import moment from "moment";
import ApexCharts from "apexcharts";
const NiftyValuation = () => {

  const [dateRange, setdateRange] = React.useState("");
  const [candlestick, setcandlestick] = React.useState({});
  const [treeData, settreeData] = React.useState({});
  const [NiftyData, setNiftyData] = React.useState({});
  const [NiftyCptions, setNiftyCptions] = React.useState({});
  const [candlestickShow, setcandlestickShow] = React.useState(false);




  const showgetColorLineChart = () => {
    let data = {};
    getColorLineChart(data).then((result) => {
      if (result.data.success) {
        // console.log(result.data)
        var series = [{name:'Nifty 50',data:[]},{name:'PE',data:[]}]
        var date = []
        var res = result.data.response;
        var yield_multiplier = []
        var roe = []
        for (let arr of res) {
          series[0].data.push(arr.nse_index)
          series[1].data.push(arr.pe)
          yield_multiplier.push(arr.yield_multiplier)
          roe.push(arr.roe)
          date.push(moment(arr.date).format("DD-MM-YYYY"))
        }

        colorlineChart(series,date)
        yieldMultiChart(yield_multiplier,date)
        NiftyRoeChart(roe,date)
      }
    });
  };
  const colorlineChart = (series,date) => {

    var options = {
      chart: {
        height: 350,
        type: "line",
        stacked: false
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#008000',"#FF1654", "#247BA0"],
      series: series,
      stroke: {
        width: [1, 1]
      },
      plotOptions: {
        bar: {
          columnWidth: "20%"
        }
      },
      xaxis: {
        categories: date,
      },
      yaxis: [
        {
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
            color: "#133337"
          },
          labels: {
            style: {
              colors: "#133337"
            }
          },
          title: {
            text: "Nifty 50 Index",
            style: {
              color: "#133337"
            }
          }
        },
        {
          opposite: true,
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
            color: "#247BA0"
          },
          labels: {
            style: {
              colors: "#247BA0"
            }
          }
          // ,
          // title: {
          //   text: "Series B",
          //   style: {
          //     color: "#247BA0"
          //   }
          // }
        }
      ],
      tooltip: {
        shared: false,
        intersect: true,
        x: {
          show: false,
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left', 
        offsetX: 40
      }
    };
    
    // var chart = new ApexCharts(document.querySelector("#chart"), options);
    
    // chart.render();

    document.querySelector("#colorlineChart").innerHTML = "";
    var chart = new ApexCharts(
      document.querySelector("#colorlineChart"),
      options
    );
    chart.render();
  };
  const yieldMultiChart = (data,date) => {

    
    var options = {
      annotations: {
        position: "front",
        yaxis: [
          {
            label: {
              text: "Over Sold"
            },
            y: 140,
            y2: 87,
            fillColor: "green"
          },
          { 
            label: {
              text: "Neutral"
            },
            y: 87,
            y2: 68,
            fillColor: "yellow"
          },
          {
            label: {
              text: "Over Heating",
            },
            y: 68,
            y2: 40,
            fillColor: "#FF0000"
          }
        ]
      },
      series: [{
        name: "Yield Multiplier %",
        data: data
    }],
      chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight',
      width: [1, 1]
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return value.toFixed(0)
        },
      }
    },
    // title: {
    //   text: 'Product Trends by Month',
    //   align: 'left'
    // },
    // grid: {
    //   row: {
    //     colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
    //     opacity: 0.5
    //   },
    // },
    xaxis: {
      categories: date,
    },
    };

    document.querySelector("#yieldMultiChart").innerHTML = "";
    var chart = new ApexCharts(
      document.querySelector("#yieldMultiChart"),
      options
    );
    chart.render();
  };
  const NiftyRoeChart = (data,date) => {
    var options = {
      series: [{
        name: "Nifty 50 RoE %",
        data: data
    }],
      chart: {
      type: 'line',
      height: 350
    },
    stroke: {
      curve: 'stepline',
      width: [1, 1]
    },
    dataLabels: {
      enabled: false
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return value.toFixed(0)
        },
      }
    },
    // title: {
    //   text: 'Stepline Chart',
    //   align: 'left'
    // },
    markers: {
      hover: {
        sizeOffset: 4
      }
    }, xaxis: {
      categories: date,
    }
    };


    document.querySelector("#NiftyRoeChart").innerHTML = "";
    var chart = new ApexCharts(
      document.querySelector("#NiftyRoeChart"),
      options
    );
    chart.render();
  };


  useEffect(() => {
    showgetColorLineChart()
  }, []);

  return (
    <div>
      <div class="psectortitle row">
        <div className="col-sm-12">
          <h1>Nifty Valuation</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a>Home</a>
              </li>
              <li class="breadcrumb-item active">Nifty Valuation</li>
            </ol>
          </nav>
        </div>

      </div>
      <section class="section dashboard">
        <div class="row">
          <div class="col-lg-12">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">
                    Nifty 50 Close vs PE
                  </h5>
                  <div id="colorlineChart"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">
                  Yield Multiplier %
                  </h5>
                  <p>(Earnings Yld / GSec Yld) * 100</p>
                  <div id="yieldMultiChart"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">
                  Nifty RoE
                  </h5>
                  <div id="NiftyRoeChart"></div>
                </div>
              </div>
            </div>
          </div>
        
        </div>
      </section>
    </div>
  );
};
export default NiftyValuation;
