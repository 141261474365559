import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './app/login';
import Header from './app/header';
import Dashboard from './app/dashboard';
import Insider from './app/insider';
import Profile from './app/my_profile'
import Protected from './app/protected';
import { Helmet } from "react-helmet";
import Mystock from './app/my_stock';
import Portfolio from './app/portfolio';
import Chinacommodity from './app/china_commodity';
import NiftyValuation from './app/nifty_valuation';
import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'react-notifications/lib/notifications.css';

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
        
function App() {
  return (
    
    <BrowserRouter>
   
      <div className="App">
      <Routes>
        <Route exact path='/' element={
              <Protected Cmp={Header}>
                <Header />
              </Protected>
            }>
          <Route path="/" element={<Dashboard />} />
          <Route path="/insider" element={<Insider />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/mystock" element={<Mystock />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/chinacommodity" element={<Chinacommodity />} />
          <Route path="/niftyvaluation" element={<NiftyValuation />} />
          <Route
                path="*"
                element={
                  // <LazyLoader>
                  <section class="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
                  <h1>404</h1>
                  <h2>The page you are looking for doesn't exist.</h2>
                  <a class="btn" >Back to home</a>
                  <img src="assets/img/not-found.svg" class="img-fluid py-5" alt="Page Not Found"/>
                  
                </section>
                  // </LazyLoader>
                }
              />
        </Route>
        <Route exact path='/login' element={< Login />}>
        
            </Route>
      </Routes>
      
      </div>
       <Helmet>
       <script src="../assets/js/main.js" type='text/babel'></script>
        <script src="../assets/vendor/apexcharts/apexcharts.min.js"  type='text/babel'/>
      <script src="../assets/vendor/bootstrap/js/bootstrap.bundle.min.js" type='text/babel'></script>
      <script src="../assets/vendor/chart.js/chart.umd.js" type='text/babel'></script>
      <script src="../assets/vendor/echarts/echarts.min.js" type='text/babel'></script>
      <script src="../assets/vendor/quill/quill.min.js" type='text/babel'></script>
      <script src="../assets/vendor/simple-datatables/simple-datatables.js" type='text/babel'></script>
      <script src="../assets/vendor/tinymce/tinymce.min.js" type='text/babel'></script>
      <script src="../assets/vendor/php-email-form/validate.js" type='text/babel'></script>
    </Helmet>
    </BrowserRouter>
  );
}

export default App;
