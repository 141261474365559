
export function echarts(props) {
    // var chartDom = document.getElementById('echarts');
    // console.log(chartDom)
    // var myChart = ReactEcharts.init(chartDom);
    var option;

    const upColor = '#067857';
    const upBorderColor = '#067857';
    const downColor = '#ec0000';
    const downBorderColor = '#ec0000';
    // Each item: open，close，lowest，highest
    // const data0 = splitData([
    //     ['2013/1/24', 2320.26, 2320.26, 2287.3, 2362.94],
    //     ['2013/1/25', 2300, 2291.3, 2288.26, 2308.38],
    //     ['2013/6/13', 2190.1, 2148.35, 2126.22, 2190.1]
    // ]);
    const data0 = splitData(props);
    function splitData(rawData) {
        // console.log(rawData)
        const categoryData = [];
        const values = [];
        for (var i = 0; i < rawData.length; i++) {
            categoryData.push(rawData[i].splice(0, 1)[0]);
            values.push(rawData[i]);
        }
        return {
            categoryData: categoryData,
            values: values
        };
    }
    function calculateMA(dayCount) {
        var result = [];
        for (var i = 0, len = data0.values.length; i < len; i++) {
            if (i < dayCount) {
                result.push('-');
                continue;
            }
            var sum = 0;
            for (var j = 0; j < dayCount; j++) {
                sum += +data0.values[i - j][1];
            }
            result.push(sum / dayCount);
        }
        return result;
    }
    option = {
       
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        legend: {
            data: ['P', 'MA11', 'MA22', 'MA200']
        },
        grid: {
            left: '10%',
            right: '10%',
            bottom: '15%'
        },
        xAxis: {
            type: 'category',
            data: data0.categoryData,
            boundaryGap: false,
            axisLine: { onZero: false },
            splitLine: { show: false },
            min: 'dataMin',
            max: 'dataMax'
        },
        yAxis: {
            scale: true,
            splitArea: {
                show: true
            }
        },
        dataZoom: [
            {
                type: 'inside',
                start: 50,
                end: 100
            },
            {
                show: true,
                type: 'slider',
                top: '90%',
                start: 50,
                end: 100
            }
        ],
        series: [
            {
                name: 'P',
                type: 'candlestick',
                data: data0.values,
                itemStyle: {
                    color: upColor,
                    color0: downColor,
                    borderColor: upBorderColor,
                    borderColor0: downBorderColor
                },
                markPoint: {
                    label: {
                        formatter: function (param) {
                            return param != null ? Math.round(param.value) + '' : '';
                        }
                    },
                    data: [
                        {
                            name: 'Mark',
                            coord: ['2013/5/31', 2300],
                            value: 2300,
                            itemStyle: {
                                color: 'rgb(41,60,85)'
                            }
                        },
                        {
                            name: 'highest value',
                            type: 'max',
                            valueDim: 'highest'
                        },
                        {
                            name: 'lowest value',
                            type: 'min',
                            valueDim: 'lowest'
                        },
                        {
                            name: 'average value on close',
                            type: 'average',
                            valueDim: 'close'
                        }
                    ],
                    tooltip: {
                        formatter: function (param) {
                            return param.name + '<br>' + (param.data.coord || '');
                        }
                    }
                },
                markLine: {
                    symbol: ['none', 'none'],
                    data: [
                        [
                            {
                                name: 'from lowest to highest',
                                type: 'min',
                                valueDim: 'lowest',
                                symbol: 'circle',
                                symbolSize: 10,
                                label: {
                                    show: false
                                },
                                emphasis: {
                                    label: {
                                        show: false
                                    }
                                }
                            },
                            {
                                type: 'max',
                                valueDim: 'highest',
                                symbol: 'circle',
                                symbolSize: 10,
                                label: {
                                    show: false
                                },
                                emphasis: {
                                    label: {
                                        show: false
                                    }
                                }
                            }
                        ],
                        {
                            name: 'min line on close',
                            type: 'min',
                            valueDim: 'close'
                        },
                        {
                            name: 'max line on close',
                            type: 'max',
                            valueDim: 'close'
                        }
                    ]
                }
            },
            {
                name: 'MA11',
                type: 'line',
                data: calculateMA(11),
                smooth: true,
                lineStyle: {
                    color:'red',
                    type: 'dashed',
                    opacity: 0.5
                }
            },
            {
                name: 'MA22',
                type: 'line',
                data: calculateMA(22),
                smooth: true,
                lineStyle: {
                    color:'green',
                    type: 'dashed',
                    opacity: 0.5
                }
            },
            {
                name: 'MA200',
                type: 'line',
                data: calculateMA(200),
                smooth: true,
                lineStyle: {
                    color:'blue',
                    type: 'dashed',
                    opacity: 0.5
                }
            }
        ]
    };
    // console.log(option)
    // option && myChart.setOption(option);
    return option;
}