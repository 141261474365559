import React, { useRef, useEffect } from "react";
import $ from "jquery";
import { getUserDetails } from "../service/common";
import { Outlet, useNavigate } from "react-router-dom";
import {
  getallUserDetails,
  getAllCompany,
  updateUserDetails,
} from "../service/service";
import Select from "react-select";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
const Profile = () => {
  let navigate = useNavigate();
  const [user_id, setuser_id] = React.useState(0);
  const [name, setname] = React.useState("");
  const [email, setemail] = React.useState("");
  const [gender, setGender] = React.useState("");

  const [allCompany, setAllCompany] = React.useState([]);
  const [allCompany_keyword, setallCompany_keyword] = React.useState([]);
  const [dropSearch, setdropSearch] = React.useState("");

  const [userStockArray, setUserStockArray] = React.useState([]);
  useEffect(() => {
    if (getUserDetails()) {
      setuser_id(getUserDetails().user_id);
      getallUserDetailsFun(getUserDetails().user_id);
    } else {
      navigate("/");
    }
    // setUserStockArray([
    //   {
    //     mapper_id: "",
    //     stock_name: "",
    //     stock_details: {},
    //     purchase_date: "",
    //     purchase_price: "",
    //     purchase_quantity: "",
    //     investment_amount: "",
    //   },
    // ]);
  }, []);

  function addNewRow() {
    setUserStockArray([
      ...userStockArray,
      {
        mapper_id: "",
        stock_name: "",
        stock_details: {},
        purchase_date: "",
        purchase_price: "",
        purchase_quantity: "",
        investment_amount: "",
      },
    ]);
  }
  const deleteNewRow = (index) => (e) => {
    var src = [...userStockArray];
    src.splice(index, 1);
    setUserStockArray(src);
  };
  const GetDateToday = () => {
    // return new Date().toISOString().split("T")[0];
    var today = new Date();
    today.setDate(today.getDate()-1)
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }

    return yyyy + "-" + mm + "-" + dd;
  };

  const updateFieldChanged = (index) => (e) => {
    const newArray = userStockArray.map((item, i) => {
      if (index === i) {
        if (e.target.name == "purchase_quantity") {
          item.investment_amount = item.purchase_price * e.target.value;
        }
        if (e.target.name == "purchase_price") {
          if (item.purchase_quantity) {
            item.investment_amount = item.purchase_quantity * e.target.value;
          }
        }
        return { ...item, [e.target.name]: e.target.value };
      } else {
        return item;
      }
    });
    setUserStockArray(newArray);
  };
  const updateFieldStockChanged = (index) => (e) => {
    var oldArray = [...userStockArray];
    const search = (what) =>
      oldArray.find((element) => element.mapper_id === what);
    const newArray = userStockArray.map((item, i) => {
      if (index === i) {
        const found = search(e.mapper_id);
        if (found) {
          NotificationManager.error(e.name + " already exist");
          return item;
        } else {
          item.stock_name = e.name;
          item.mapper_id = e.mapper_id;
          return { ...item, ["stock_details"]: e };
        }
      } else {
        return item;
      }
    });
    setUserStockArray(newArray);
  };

  const copyPortfolio = () => {
    var oldArray = [...userStockArray];
    const search = (what) =>
      oldArray.find((element) => element.mapper_id === what);
    for (let arr of allCompany_keyword) {
      const found = search(arr.mapper_id);
      if (found) {
        NotificationManager.error(arr.name + " already exist");
      } else {
        oldArray.push({
          mapper_id: arr.mapper_id,
          stock_name: arr.name,
          stock_details: arr,
          purchase_date: "",
          purchase_price: "",
          purchase_quantity: "",
          investment_amount: "",
        });
      }
    }
    setUserStockArray(oldArray);
  };
  const getallUserDetailsFun = (userId) => {
    let data = {
      user_id: userId,
    };
    getallUserDetails(data).then((result) => {
      if (result.data.success) {
        setname(result.data.userDetails.name);
        setemail(result.data.userDetails.email);
        setGender(result.data.userDetails.gender);
        var stocks = result.data.userDetails.stocks;
        setallCompany_keyword(JSON.parse(stocks));
        for (let arr of result.data.userDetails.user_stocks) {
          arr.stock_details = JSON.parse(arr.stock_details);
        }
        setUserStockArray(result.data.userDetails.user_stocks);
      }
    });
  };
  const showCompany = () => {
    let data = {
      search: dropSearch,
    };
    getAllCompany(data).then((result) => {
      if (result.data.success) {
        var res = result.data.response;
        for (let arr of res) {
          arr.value = arr.mapper_id;
          arr.label = arr.name + " (" + arr.nse + ")";
        }
        setAllCompany(res);
      }
    });
  };
  useEffect(() => {
    showCompany();
  }, [dropSearch]);
  function handlesetCompany(data) {
    setallCompany_keyword(data);
  }
  const handleInputChange = (value) => {
    if (value.length >= 2) {
      setdropSearch(value);
    } else {
      setAllCompany([]);
    }

    // setValue(value);
  };
  const updateCompany = () => {
    var stArr = [];
    // return false;
    for (let arr of userStockArray) {
      if (arr.mapper_id == "") {
        NotificationManager.error("Please enter stock detalis");
        return false;
      }
      if (arr.stock_name == "") {
        NotificationManager.error("Please enter stock name");
        return false;
      }
      if (arr.purchase_date == "") {
        NotificationManager.error("Please select purchase date");
        return false;
      }
      if (arr.purchase_price == "") {
        NotificationManager.error("Please enter purchase price");
        return false;
      }
      if (arr.purchase_quantity == "") {
        NotificationManager.error("Please enter purchase quantity");
        return false;
      }
      stArr.push(arr);
    }
    if (!name) {
      NotificationManager.error("Please enter name");
      return false;
    }
    if (!gender) {
      NotificationManager.error("Please select gender");
      return false;
    }
    // for (let arr of stArr) {
    //   arr.stock_details = JSON.stringify(arr.stock_details);
    // }
    let data = {
      name: name,
      gender: gender,
      stocks: JSON.stringify(allCompany_keyword),
      user_id: user_id,
      user_stocks: stArr,
    };
    updateUserDetails(data).then((result) => {
      if (result.data.success) {
        NotificationManager.success(result.data.massage);
      } else {
        NotificationManager.error(result.data.massage);
      }
    });
  };
  return (
    <div>
      <NotificationContainer />
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">My Profile</h5>

              <form>
                <div class="row mb-3">
                  <label for="inputText" class="col-sm-2 col-form-label">
                    Name
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      value={name}
                      onChange={(e) => {
                        setname(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputEmail" class="col-sm-2 col-form-label">
                    Email
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="email"
                      class="form-control"
                      value={email}
                      onChange={(e) => {
                        setemail(e.target.value);
                      }}
                      disabled
                    />
                  </div>
                </div>

                <fieldset class="row mb-3">
                  <legend class="col-form-label col-sm-2 pt-0">Gender</legend>
                  <div class="col-sm-1">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="gridRadios"
                        id="gridRadios1"
                        value="male"
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                        checked={gender === "male"}
                      />
                      <label class="form-check-label" for="gridRadios1">
                        Male
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-5">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="gridRadios"
                        id="gridRadios2"
                        value="female"
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                        checked={gender === "female"}
                      />
                      <label class="form-check-label" for="gridRadios2">
                        Female
                      </label>
                    </div>
                  </div>
                </fieldset>
                <div class="row mb-3">
                  <label for="inputStock" class="col-sm-2 col-form-label">
                    Your Watchlist Stocks
                  </label>
                  <div class="col-sm-10">
                    <Select
                      options={allCompany}
                      placeholder="Company Name"
                      value={allCompany_keyword}
                      onInputChange={handleInputChange}
                      onChange={handlesetCompany}
                      isSearchable={true}
                      isMulti
                    />
                  </div>
                  <div className="col-sm-12 text-end mt-3">
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={copyPortfolio}
                    >
                      Copy to Portfolio
                    </button>
                    {/* <button className="btn btn-primary" >Copy to Portfolio</button> */}
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputStock" class="col-sm-2 col-form-label">
                    Your Portfolio
                  </label>
                  <div class="col-sm-10">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Stock Name</th>
                          <th>Purchase Date</th>
                          <th>Purchase Price</th>
                          <th>Purchase Quantity</th>
                          <th>Investment Amount</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userStockArray.map((item, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>
                              <Select
                                options={allCompany}
                                placeholder="Company Name"
                                value={item.stock_details}
                                onInputChange={handleInputChange}
                                name="stock_details"
                                onChange={updateFieldStockChanged(index)}
                                isSearchable={true}
                              />
                            </td>
                            <td>
                              <input
                                type="date"
                                class="form-control"
                                name="purchase_date"
                                max={GetDateToday()}
                                value={item.purchase_date}
                                onChange={updateFieldChanged(index)}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control"
                                min="0"
                                name="purchase_price"
                                value={item.purchase_price}
                                onChange={updateFieldChanged(index)}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control"
                                min="0"
                                name="purchase_quantity"
                                value={item.purchase_quantity}
                                onChange={updateFieldChanged(index)}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control"
                                min="0"
                                name="investment_amount"
                                value={item.investment_amount}
                                onChange={updateFieldChanged(index)}
                                disabled
                              />
                            </td>
                            <td>
                              <button
                                type="button"
                                class="btn btn-danger"
                                onClick={deleteNewRow(index)}
                              >
                                <i class="bi bi-trash"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div class="col-sm-12 text-end">
                    <button
                      type="button"
                      class="btn btn-warning"
                      onClick={addNewRow}
                    >
                      <i class="bi bi-file-plus"></i> Another Stock
                    </button>
                  </div>
                </div>

                <div class="row mb-3 mt-5">
                  <div class="col-sm-12 text-end">
                    <button
                      type="button"
                      class="btn btn-success"
                      onClick={updateCompany}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Profile;
