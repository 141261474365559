import React, { useRef, useEffect } from "react";
import $ from "jquery";
import { Outlet, useNavigate } from "react-router-dom";
import Select from "react-select";
import LoginModal from "./modal/loginModal";
const Header = () => {
  let navigate = useNavigate();
  const [userName, setuserName] = React.useState("");
  const [passWord, setpassWord] = React.useState("");
  const [sideOpen, setsideOpen] = React.useState(false);
  const [isLogin, setisLogin] = React.useState(false);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [scrollTop, setScrollTop] = React.useState(0);
  const optionList = [
    { value: "red", label: "Red" },
    { value: "green", label: "Green" },
    { value: "yellow", label: "Yellow" },
    { value: "blue", label: "Blue" },
    { value: "white", label: "White" },
  ];
  useEffect(() => {
    getUserDetails();
    // const handleScroll = (event) => {
    //   if (window.scrollY > 400 && isLogin == false) {
    //     $("#newClick").click();
    //     console.log(window.scrollY);
    //   }
    //   setScrollTop(window.scrollY);
    // };

    // window.addEventListener("scroll", handleScroll);

    // return () => {
    //   window.removeEventListener("scroll", handleScroll);
    // };
  }, [isLogin]);
  const getUserDetails = () => {
    if (localStorage.getItem("stockuser")) {
      setisLogin(true);
      setuserName(JSON.parse(localStorage.getItem("stockuser")).name);
    } else {
      setisLogin(false);
    }
  };
  const toggleBtn = () => {
    // if (select('.toggle-sidebar-btn')) {
    //     on('click', '.toggle-sidebar-btn', function(e) {
    //       select('body').classList.toggle('toggle-sidebar')
    //     })
    //   }
    if (sideOpen) {
      $("body").removeClass("toggle-sidebar");
      setsideOpen(false);
    } else {
      $("body").addClass("toggle-sidebar");
      setsideOpen(true);
    }
  };
  const routerChange = (url) => {
    navigate("/" + url);
  };
  function handleSelect(data) {
    setSelectedOptions(data);
  }
  function signOut(data) {
    localStorage.removeItem("stockuser");
    setisLogin(false);
    window.location.reload();
  }
  return (
    <div>
      <header id="header" class="header fixed-top d-flex align-items-center">
        <div class="d-flex align-items-center justify-content-between">
          <a
            class="logo d-flex align-items-center"
            onClick={() => {
              routerChange("");
            }}
          >
            <img src="assets/img/WealthGame Logo Square.png" alt="" />
            <span class="d-none d-lg-block">WealthGame</span>
          </a>
          <i class="bi bi-list toggle-sidebar-btn" onClick={toggleBtn}></i>
        </div>

        {/* <div class="search-bar">
                    <form class="search-form d-flex align-items-center" method="POST" action="#">
                    <div className="dropdown-container">
                        <Select
                        options={optionList}
                        placeholder="Search.."
                        value={selectedOptions}
                        onChange={handleSelect}
                        isSearchable={true}
                        isMulti
                        />
                    </div>
                    </form>
                </div> */}

        <nav class="header-nav ms-auto">
          <ul class="d-flex align-items-center">
            <li class="nav-item d-block d-lg-none">
              <a class="nav-link nav-icon search-bar-toggle " href="#">
                <i class="bi bi-search"></i>
              </a>
            </li>

            <li class="nav-item dropdown">
              {/* <a class="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                                <i class="bi bi-bell"></i>
                                <span class="badge bg-primary badge-number">4</span>
                            </a> */}

              <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                <li class="dropdown-header">
                  You have 4 new notifications
                  <a href="#">
                    <span class="badge rounded-pill bg-primary p-2 ms-2">
                      View all
                    </span>
                  </a>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>

                <li class="notification-item">
                  <i class="bi bi-exclamation-circle text-warning"></i>
                  <div>
                    <h4>Lorem Ipsum</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>30 min. ago</p>
                  </div>
                </li>

                <li>
                  <hr class="dropdown-divider" />
                </li>

                <li class="notification-item">
                  <i class="bi bi-x-circle text-danger"></i>
                  <div>
                    <h4>Atque rerum nesciunt</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>1 hr. ago</p>
                  </div>
                </li>

                <li>
                  <hr class="dropdown-divider" />
                </li>

                <li class="notification-item">
                  <i class="bi bi-check-circle text-success"></i>
                  <div>
                    <h4>Sit rerum fuga</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>2 hrs. ago</p>
                  </div>
                </li>

                <li>
                  <hr class="dropdown-divider" />
                </li>

                <li class="notification-item">
                  <i class="bi bi-info-circle text-primary"></i>
                  <div>
                    <h4>Dicta reprehenderit</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>4 hrs. ago</p>
                  </div>
                </li>

                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li class="dropdown-footer">
                  <a href="#">Show all notifications</a>
                </li>
              </ul>
              {/* <!-- End Notification Dropdown Items --> */}
            </li>
            {/* <!-- End Notification Nav --> */}

            <li class="nav-item dropdown">
              {/* <a class="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                                <i class="bi bi-chat-left-text"></i>
                                <span class="badge bg-success badge-number">3</span>
                            </a> */}
              {/* <!-- End Messages Icon --> */}

              <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
                <li class="dropdown-header">
                  You have 3 new messages
                  <a href="#">
                    <span class="badge rounded-pill bg-primary p-2 ms-2">
                      View all
                    </span>
                  </a>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>

                <li class="message-item">
                  <a href="#">
                    <img
                      src="assets/img/messages-1.jpg"
                      alt=""
                      class="rounded-circle"
                    />
                    <div>
                      <h4>Maria Hudson</h4>
                      <p>
                        Velit asperiores et ducimus soluta repudiandae labore
                        officia est ut...
                      </p>
                      <p>4 hrs. ago</p>
                    </div>
                  </a>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>

                <li class="message-item">
                  <a href="#">
                    <img
                      src="assets/img/messages-2.jpg"
                      alt=""
                      class="rounded-circle"
                    />
                    <div>
                      <h4>Anna Nelson</h4>
                      <p>
                        Velit asperiores et ducimus soluta repudiandae labore
                        officia est ut...
                      </p>
                      <p>6 hrs. ago</p>
                    </div>
                  </a>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>

                <li class="message-item">
                  <a href="#">
                    <img
                      src="assets/img/messages-3.jpg"
                      alt=""
                      class="rounded-circle"
                    />
                    <div>
                      <h4>David Muldon</h4>
                      <p>
                        Velit asperiores et ducimus soluta repudiandae labore
                        officia est ut...
                      </p>
                      <p>8 hrs. ago</p>
                    </div>
                  </a>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>

                <li class="dropdown-footer">
                  <a href="#">Show all messages</a>
                </li>
              </ul>
              {/* <!-- End Messages Dropdown Items --> */}
            </li>
            {/* <!-- End Messages Nav --> */}
            <li
              class="nav-item dropdown pe-3"
              style={!isLogin ? {} : { display: "none" }}
            >
              <a
                class="nav-link nav-profile d-flex align-items-center pe-0"
                style={{ cursor: "pointer" }}
              >
                {/* <span class="d-none d-md-block  ps-2">Login</span> */}
                <LoginModal />
              </a>
            </li>
            <li
              class="nav-item dropdown pe-3"
              style={isLogin ? {} : { display: "none" }}
            >
              <a
                class="nav-link nav-profile d-flex align-items-center pe-0"
                href="#"
                data-bs-toggle="dropdown"
              >
                <img
                  src="assets/img/profile-img.jpg"
                  alt="Profile"
                  class="rounded-circle"
                />
                <span class="d-none d-md-block dropdown-toggle ps-2">
                  {userName}
                </span>
              </a>
              {/* <!-- End Profile Iamge Icon --> */}

              <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li class="dropdown-header">
                  <h6>{userName}</h6>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>

                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      routerChange("profile");
                    }}
                  >
                    <i class="bi bi-person"></i>
                    <span>My Profile</span>
                  </a>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>

                {/* <li>
                                    <a class="dropdown-item d-flex align-items-center" href="users-profile.html">
                                        <i class="bi bi-gear"></i>
                                        <span>Account Settings</span>
                                    </a>
                                </li>
                                <li>
                                    <hr class="dropdown-divider" />
                                </li>

                                <li>
                                    <a class="dropdown-item d-flex align-items-center" href="pages-faq.html">
                                        <i class="bi bi-question-circle"></i>
                                        <span>Need Help?</span>
                                    </a>
                                </li>
                                <li>
                                    <hr class="dropdown-divider" />
                                </li> */}

                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={signOut}
                  >
                    <i class="bi bi-box-arrow-right"></i>
                    <span>Sign Out</span>
                  </a>
                </li>
              </ul>
              {/* <!-- End Profile Dropdown Items --> */}
            </li>
            {/* <!-- End Profile Nav --> */}
          </ul>
        </nav>
        {/* <!-- End Icons Navigation --> */}
      </header>
      {/* <!-- End Header --> */}
      {/* <!-- ======= Sidebar ======= --> */}
      <aside id="sidebar" class="sidebar">
        <ul class="sidebar-nav" id="sidebar-nav">
          <li class="nav-item">
            <a
              class="nav-link "
              onClick={() => {
                routerChange("");
              }}
            >
              <i class="ri-camera-lens-line"></i>
              <span>Periscope</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link "
              onClick={() => {
                routerChange("insider");
              }}
            >
              <i class="bi bi-binoculars"></i>
              <span>Insider</span>
            </a>
          </li>
          {/* <li class="nav-item">
            <a
              class="nav-link "
              onClick={() => {
                routerChange("portfolio");
              }}
            >
              <i class="bi bi-upc-scan"></i>
              <span>Portfolio Scanner</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link "
              onClick={() => {
                routerChange("discover");
              }}
            >
              <i class="ri-stock-fill"></i>
              <span>Discover</span>
            </a>
          </li> */}
          <li class="nav-item">
            <a
              class="nav-link "
              onClick={() => {
                routerChange("mystock");
              }}
            >
              <i class="ri-account-circle-line"></i>
              <span>My Workspace</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link "
              onClick={() => {
                routerChange("chinacommodity");
              }}
            >
              <i class="ri-alipay-line"></i>
              <span>China Commodity</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link "
              onClick={() => {
                routerChange("niftyvaluation");
              }}
            >
              <i class="ri-alipay-line"></i>
              <span>Nifty Valuation</span>
            </a>
          </li>

          {/* <!-- End Dashboard Nav --> */}

          {/* <li class="nav-item">
                        <a class="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
                            <i class="bi bi-menu-button-wide"></i><span>Components</span><i class="bi bi-chevron-down ms-auto"></i>
                        </a>
                        <ul id="components-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
                            <li>
                                <a href="components-alerts.html">
                                    <i class="bi bi-circle"></i><span>Alerts</span>
                                </a>
                            </li>
                            <li>
                                <a href="components-accordion.html">
                                    <i class="bi bi-circle"></i><span>Accordion</span>
                                </a>
                            </li>
                            <li>
                                <a href="components-badges.html">
                                    <i class="bi bi-circle"></i><span>Badges</span>
                                </a>
                            </li>
                            <li>
                                <a href="components-breadcrumbs.html">
                                    <i class="bi bi-circle"></i><span>Breadcrumbs</span>
                                </a>
                            </li>
                            <li>
                                <a href="components-buttons.html">
                                    <i class="bi bi-circle"></i><span>Buttons</span>
                                </a>
                            </li>
                            <li>
                                <a href="components-cards.html">
                                    <i class="bi bi-circle"></i><span>Cards</span>
                                </a>
                            </li>
                            <li>
                                <a href="components-carousel.html">
                                    <i class="bi bi-circle"></i><span>Carousel</span>
                                </a>
                            </li>
                            <li>
                                <a href="components-list-group.html">
                                    <i class="bi bi-circle"></i><span>List group</span>
                                </a>
                            </li>
                            <li>
                                <a href="components-modal.html">
                                    <i class="bi bi-circle"></i><span>Modal</span>
                                </a>
                            </li>
                            <li>
                                <a href="components-tabs.html">
                                    <i class="bi bi-circle"></i><span>Tabs</span>
                                </a>
                            </li>
                            <li>
                                <a href="components-pagination.html">
                                    <i class="bi bi-circle"></i><span>Pagination</span>
                                </a>
                            </li>
                            <li>
                                <a href="components-progress.html">
                                    <i class="bi bi-circle"></i><span>Progress</span>
                                </a>
                            </li>
                            <li>
                                <a href="components-spinners.html">
                                    <i class="bi bi-circle"></i><span>Spinners</span>
                                </a>
                            </li>
                            <li>
                                <a href="components-tooltips.html">
                                    <i class="bi bi-circle"></i><span>Tooltips</span>
                                </a>
                            </li>
                        </ul>
                    </li> */}
          {/* <!-- End Components Nav --> */}
        </ul>
      </aside>
      {/* <!-- End Sidebar--> */}
      <main id="main" class="main">
        {/* <!-- End Page Title --> */}
        <Outlet />
      </main>

      <footer id="footer" class="footer">
        <div class="copyright">
          &copy; Copyright{" "}
          <strong>
            <span>WealthGame</span>
          </strong>
          . All Rights Reserved
        </div>
        <div class="credits">
          {/* <!-- All the links in the footer should remain intact. -->
                <!-- You can delete the links only if you purchased the pro version. -->
                <!-- Licensing information: https://bootstrapmade.com/license/ -->
                <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/ --> */}
          {/* Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a> */}
        </div>
      </footer>
    </div>
  );
};

export default Header;
