import React, { useRef, useEffect } from 'react'
import $ from 'jquery';
import { getActionType, getInsiderBuySell, getInstrumentType, getTransactionBy, getBuySellType, getNewsKeyword, getPublicAnnouncements, getLatestEarning, getBusinessNews, getAllCompany } from '../service/service';
import moment from 'moment';
import Select from "react-select";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:

const Dashboard = () => {
  const [sellList, setsellList] = React.useState([])
  const [actionType, setactionType] = React.useState([])
  const [instrumentType, setinstrumentType] = React.useState([])
  const [transactionBy, settransactionBy] = React.useState([])
  const [buySellType, setbuySellType] = React.useState([])
  const [newsKeyword, setnewsKeyword] = React.useState([])
  const [allCompany, setAllCompany] = React.useState([])
  const [publicAnnouncements, setpublicAnnouncements] = React.useState([])
  const [latestEarning, setlatestEarning] = React.useState([])
  const [businessNews, setbusinessNews] = React.useState([])

  const [action_type, setaction_type] = React.useState([])
  const [news_keyword, setnews_keyword] = React.useState([])
  const [type_pre, settype_pre] = React.useState([])
  const [entity_keyword, setentity_keyword] = React.useState([])
  const [action_keyword, setaction_keyword] = React.useState([])
  const [allCompany_keyword, setallCompany_keyword] = React.useState([])

  const [totalPage, setTotalPage] = React.useState(0);
  const [limit, setLimit] = React.useState("20");
  const [offset, setOffset] = React.useState("0");

  const [totalPageEarn, setTotalPageEarn] = React.useState(0);
  const [limitEarn, setLimitEarn] = React.useState("20");
  const [offsetEarn, setOffsetEarn] = React.useState("0");

  const [totalPageNews, setTotalPageNews] = React.useState(0);
  const [limitNews, setLimitNews] = React.useState("20");
  const [offsetNews, setOffsetNews] = React.useState("0");

  const [totalPageAnnouncement, setTotalPageAnnouncement] = React.useState(0);
  const [limitAnnouncement, setLimitAnnouncement] = React.useState("20");
  const [offsetAnnouncement, setOffsetAnnouncement] = React.useState("0");
  
  const [dateRangeNews, setdateRangeNews] = React.useState("");
  const [dropSearch, setdropSearch] = React.useState("");


  const showActionType = () => {
    let data = {
    }
    getActionType(data).then(result => {
      if (result.data.success) {
        var res = result.data.response
        for (let arr of res) {
          arr.value = arr.action_type
          arr.label = arr.action_type
        }
        setactionType(res)
      }

    })

  }
  const showCompany = () => {
    let data = {
      search: dropSearch
    }
    getAllCompany(data).then(result => {
      if (result.data.success) {
        var res = result.data.response
        for (let arr of res) {
          arr.value = arr.mapper_id
          arr.label = arr.name+' ('+arr.nse+')'
        }
        setAllCompany(res)
      }

    })

  }
  const showInstrumentType = () => {
    let data = {
    }
    getInstrumentType(data).then(result => {
      if (result.data.success) {
        var res = result.data.response
        for (let arr of res) {
          arr.value = arr.type_pre
          arr.label = arr.type_pre
        }

        setinstrumentType(res)
      }

    })

  }
  const showTransactionBy = () => {
    let data = {
    }
    getTransactionBy(data).then(result => {
      if (result.data.success) {
        var res = result.data.response
        for (let arr of res) {
          arr.value = arr.entity_keyword
          arr.label = arr.entity_keyword
        }
        settransactionBy(res)
      }

    })

  }
  const showGetBuySellType = () => {
    let data = {
    }

    getBuySellType(data).then(result => {
      if (result.data.success) {
        var res = result.data.response
        for (let arr of res) {
          arr.value = arr.action_keyword
          arr.label = arr.action_keyword
        }

        setbuySellType(res)
      }

    })

  }
  function capitalizeFirstLetter(str) {

    // converting first letter to uppercase
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

    return capitalized;
  }

  const showGetNewsKeyword = () => {
    let data = {
    }
    getNewsKeyword(data).then(result => {
      if (result.data.success) {
        var res = result.data.response
        for (let arr of res) {
          arr.value = arr.keyword
          arr.label = capitalizeFirstLetter(arr.keyword)
        }
        setnewsKeyword(res)
      }

    })

  }
  const showGetPublicAnnouncements = () => {
    var newsKey = getArrVal(news_keyword)
    var allCompany = ''
    if (allCompany_keyword.value) {
      allCompany = allCompany_keyword.value
    }

    let data = {
      "limit": limitAnnouncement,
      "start": offsetAnnouncement,
      "keyword": newsKey,
      "dateRange": dateRangeNews,
      "allCompany": allCompany.toString()
    }
    getPublicAnnouncements(data).then(result => {
      if (result.data.success) {
        setpublicAnnouncements(result.data.response)
        let totalPage = Math.ceil(result.data.total / limit);
        setTotalPageAnnouncement(totalPage);
      }

    })

  }
  const showGetLatestEarning = () => {
    var allCompany = ''
    if (allCompany_keyword.value) {
      allCompany = allCompany_keyword.value
    }
    let data = {
      "limit": limitEarn,
      "start": offsetEarn,
      "dateRange": dateRangeNews,
      "allCompany": allCompany.toString()
    }
    getLatestEarning(data).then(result => {
      if (result.data.success) {
        setlatestEarning(result.data.response)
        let totalPage = Math.ceil(result.data.total / limit);
        setTotalPageEarn(totalPage);
      }

    })

  }
  const showGetBusinessNews = () => {
    var allCompany = ''
    if (allCompany_keyword.value) {
      allCompany = allCompany_keyword.value
    }
    let data = {
      "limit": limitNews,
      "start": offsetNews,
      "dateRange": dateRangeNews,
      "allCompany": allCompany.toString()
    }
    getBusinessNews(data).then(result => {
      if (result.data.success) {
        setbusinessNews(result.data.response)
        let totalPage = Math.ceil(result.data.total / limit);
        setTotalPageNews(totalPage);
      }

    })

  }
  function pageLimitChange(e) {
    setLimit(e.target.value);
  }
  const reqData = {
    "limit": limit,
    "start": offset,
    "transactionType": action_type,
    "instrumentType": type_pre,
    "transactionBy": entity_keyword,
    "buySellType": action_keyword
  }
  const handleChangePage = (e, val) => {
    let offeset = (val - 1) * limit;
    setOffset(offeset);
  };
  const handleChangePageEarn = (e, val) => {
    let offeset = (val - 1) * limit;
    setOffsetEarn(offeset);
  };
  const handleChangePageAnnouncement = (e, val) => {
    let offeset = (val - 1) * limit;
    setOffsetAnnouncement(offeset);
  };
  const handleChangePageNews = (e, val) => {
    let offeset = (val - 1) * limit;
    setOffsetNews(offeset);
  };
  function getArrVal(obj) {
    var newArr = []
    for (let arr of obj) {
      newArr.push(arr.value)
    }
    return newArr;
  }
  const InsiderBuySell = () => {
    var actionType = getArrVal(action_type)
    var typePre = getArrVal(type_pre)
    var entityKeyword = getArrVal(entity_keyword)
    var actionKeyword = getArrVal(action_keyword)
    var allCompany = ''
    if (allCompany_keyword.value) {
      allCompany = allCompany_keyword.value
    }

    let data = {
      "limit": limit,
      "start": offset,
      "transactionType": actionType,
      "instrumentType": typePre,
      "transactionBy": entityKeyword,
      "buySellType": actionKeyword,
      "dateRange": dateRangeNews,
      "allCompany": allCompany.toString()
    }
    getInsiderBuySell(data).then(result => {
      if (result.data.success) {
        setsellList(result.data.response)
        let totalPage = Math.ceil(result.data.total / limit);
        setTotalPage(totalPage);
      }


    })

  }
  useEffect(() => {
    InsiderBuySell()
  }, [limit, offset, action_type, type_pre, entity_keyword, action_keyword,dateRangeNews,allCompany_keyword])

  useEffect(() => {
    showGetLatestEarning()
  }, [limitEarn, offsetEarn,dateRangeNews, allCompany_keyword])

  useEffect(() => {
    showGetBusinessNews()
  }, [limitNews, offsetNews,dateRangeNews, allCompany_keyword])

  useEffect(() => {
    showGetPublicAnnouncements()
  }, [limitAnnouncement, offsetAnnouncement, news_keyword, dateRangeNews, allCompany_keyword])

  useEffect(() => {
    showCompany()
  }, [dropSearch])
  useEffect(() => {
    showActionType()


    showInstrumentType()
    showTransactionBy()
    showGetBuySellType()
    showGetNewsKeyword()
  }, [])
  function handlesetActionType(data) {
    setaction_type(data);
  }
  function handlesetCompany(data) {
    setOffset(0)
    setOffsetEarn(0)
    setOffsetNews(0)
    setOffsetAnnouncement(0)
    setallCompany_keyword(data);
  }
  function handlesetTypePre(data) {
    settype_pre(data);
  }
  function handlesetTypeNews(data) {
    setOffsetAnnouncement(0)
    setnews_keyword(data);
  }
  function handlesetEntityKeyword(data) {
    setentity_keyword(data);
  }
  function handlessetActionKeyword(data) {
    setaction_keyword(data);
  }
  function handleEvent(event, picker) {
    
  }
  function handleCallback(event, picker) {
    var dateRange = "'" + moment(picker.startDate).format('YYYY-MM-DD') + " 00:00:00' and '" + moment(picker.endDate).format('YYYY-MM-DD') + " 23:59:59'"
    setOffsetNews(0)
    setOffsetEarn(0)
    setOffset (0)
    setOffsetAnnouncement(0)
    setdateRangeNews(dateRange)
    
  }
  function timeDifference(current, previous) {
    
    var localTime = new Date(previous);
    // var localTime = moment.utc(previous).local().format("x");
    // console.log(localTime)
    // var local_date= moment.utc(myDate).local().format('YYYY-MM-DD HH:mm:ss');
    previous = localTime.getTime()
    var current = current +19800000
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;
    var elapsed = current - previous;

    if (elapsed < msPerMinute) {
      return Math.round(elapsed / 1000) + ' seconds ago';
    }

    else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + ' minutes ago';
    }

    else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + ' hours ago';
    }

    else if (elapsed < msPerMonth) {

      return Math.round(elapsed / msPerDay) + ' days ago';
    }

    else if (elapsed < msPerYear) {
      return moment(previous).format('DD-MM-YYYY')
      // return 'approximately ' + Math.round(elapsed / msPerMonth) + ' months ago';
    }

    else {
      return moment(previous).format('DD-MM-YYYY')
      // return 'approximately ' + Math.round(elapsed / msPerYear) + ' years ago';
    }
  }
  const handleInputChange = value => {
    if (value.length >= 2) {
      setdropSearch(value)
    } else {
      setAllCompany([])
    }

    // setValue(value);
  };
  return (
    <div>
      <div class="pagetitle row">
        <div className="col-sm-9">
          <h1>Home</h1>
          <nav>
            <ol class="breadcrumb">
              {/* <li class="breadcrumb-item"><a >Home</a></li> */}
              <li class="breadcrumb-item active">Home</li>
            </ol>
          </nav>
        </div>

        <div className="col-sm-3">
          <Select
            options={allCompany}
            placeholder="Company Name"
            value={allCompany_keyword}
            onInputChange={handleInputChange}
            onChange={handlesetCompany}
            isSearchable={true}
          // isMulti
          />
        </div>
      </div>
      <section class="section dashboard">
        <div class="row">

          {/* <!-- Left side columns --> */}
          <div class="col-lg-6">
            <div class="row">



              {/* <!-- Reports --> */}
              <div class="col-12">
                <div class="card">

                  {/* <div class="filter">
                    <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                      <li class="dropdown-header text-start">
                        <h6>Filter</h6>
                      </li>

                      <li><a class="dropdown-item" href="#">Today</a></li>
                      <li><a class="dropdown-item" href="#">This Month</a></li>
                      <li><a class="dropdown-item" href="#">This Year</a></li>
                    </ul>
                  </div> */}

                  <div class="card-body">
                    <h5 class="card-title">Filters </h5>
                    <div className='row'>
                      <div className='col-sm-3'>
                        <div className="">
                          <Select
                            options={actionType}
                            placeholder="Transaction type"
                            value={action_type}
                            onChange={handlesetActionType}
                            isSearchable={true}
                            isMulti
                          />
                        </div>
                      </div>
                      <div className='col-sm-3'>
                        <div className="">
                          <Select
                            options={instrumentType}
                            placeholder="Instrument type"
                            value={type_pre}
                            onChange={handlesetTypePre}
                            isSearchable={true}
                            isMulti
                          />
                        </div>
                      </div>
                      <div className='col-sm-3'>
                        <div className="">
                          <Select
                            options={transactionBy}
                            placeholder="Transaction By"
                            value={entity_keyword}
                            onChange={handlesetEntityKeyword}
                            isSearchable={true}
                            isMulti
                          />
                        </div>
                      </div>
                      <div className='col-sm-3'>
                        <div className="">
                          <Select
                            options={buySellType}
                            placeholder="Buy Sell Type"
                            value={action_keyword}
                            onChange={handlessetActionKeyword}
                            isSearchable={true}
                            isMulti
                          />
                        </div>
                      </div>
                      <div className='col-sm-2'>

                      </div>
                      <div className='col-sm-2'></div>
                    </div>




                  </div>

                </div>
              </div>
              {/* <!-- End Reports --> */}

              {/* <!-- Recent Sales --> */}
              <div class="col-12">
                <div class="card recent-sales overflow-auto">



                  <div class="card-body">
                    <h5 class="card-title">Insider Buy Sell</h5>
                    <div class="autoheight">
                      <table class="table datatable autoheight">
                        <thead>
                          <tr>
                            <th scope="col" >Company</th>
                            <th scope="col">Transaction Type</th>
                            <th scope="col">No of shares</th>
                            <th scope="col">Amount (in mn)</th>
                            <th scope="col">Date</th>
                          </tr>
                        </thead>
                        <tbody>

                          {
                            sellList.map((data, i) =>
                              <tr key={i}>
                                <td style={{ maxWidth: '100px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', cursor: 'pointer' }}>{data.name}</td>
                                <td>{data.action_keyword}</td>
                                <td>{data.qty.toLocaleString("en-IN", { maximumFractionDigits: 0 })}</td>
                                <td>{(data.amount / 1000000).toLocaleString("en-IN", { maximumFractionDigits: 0 })}</td>
                                <td>{moment(data.date).format('DD-MM-YYYY')}</td>
                              </tr>
                            )
                          }


                        </tbody>
                      </table>
                    </div>


                    <div className="paginati mt-3">
                      <div className="paginati_l"></div>
                      <div className="paginati_r">
                        <Stack spacing={2}>
                          <Pagination count={totalPage} shape="rounded" onChange={(e, value) => handleChangePage(e, value)} />
                        </Stack>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              {/* <!-- End Recent Sales --> */}

              {/* <!-- Top Selling --> */}
              <div class="col-12">
                <div class="card top-selling overflow-auto">



                  <div class="card-body">
                    <h5 class="card-title">Latest Earning Call Transcripts</h5>
                    <div class="autoheight">
                      <table class="table ">
                        <thead>
                          <tr>
                            <th scope="col">Company</th>
                            <th scope="col">Details</th>
                            <th scope="col">Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            latestEarning.map((data, i) =>
                              <tr key={i}>
                                <td>{data.name}</td>
                                <td style={{ maxWidth: '200px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', cursor: 'pointer' }} data-toggle="tooltip" title={data.narration}> <a href={data.url} target="_blank">{data.narration}</a> </td>
                                <td> {timeDifference(Date.now(), data.date)}</td>
                              </tr>
                            )
                          }

                        </tbody>
                      </table>
                    </div>


                    <div className="paginati mt-3">
                      <div className="paginati_l"></div>
                      <div className="paginati_r">
                        <Stack spacing={2}>
                          <Pagination count={totalPageEarn} shape="rounded" onChange={(e, value) => handleChangePageEarn(e, value)} />
                        </Stack>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              {/* <!-- End Top Selling --> */}

            </div>
          </div>
          {/* <!-- End Left side columns --> */}

          {/* <!-- Right side columns --> */}
          <div class="col-lg-6">
            <div class="col-12">
              <div class="card">

                {/* <div class="filter">
                    <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                      <li class="dropdown-header text-start">
                        <h6>Filter</h6>
                      </li>

                      <li><a class="dropdown-item" href="#">Today</a></li>
                      <li><a class="dropdown-item" href="#">This Month</a></li>
                      <li><a class="dropdown-item" href="#">This Year</a></li>
                    </ul>
                  </div> */}

                <div class="card-body">
                  <h5 class="card-title">Filters </h5>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <div className="">
                        <DateRangePicker onEvent={handleEvent} onApply={handleCallback}
                        >
                          <input className='form-control' />
                        </DateRangePicker>
                      </div>
                    </div>
                    <div className='col-sm-4'>
                      <div className="">
                        <Select
                          options={newsKeyword}
                          placeholder="News Keyword"
                          value={news_keyword}
                          onChange={handlesetTypeNews}
                          isSearchable={true}
                          isMulti
                        />
                      </div>
                    </div>
                    <div className='col-sm-4'>
                      <div className="">

                      </div>
                    </div>

                  </div>




                </div>

              </div>
            </div>
            {/* <!-- Recent Activity --> */}
            <div class="card top-selling overflow-auto">



              <div class="card-body ">
                <h5 class="card-title">Public Announcements</h5>

                <div class="autoheight">
                  <table class="table ">
                    <thead>
                      <tr>
                        {/* <th scope="col">NSE Code</th> */}
                        <th scope="col">Company</th>
                        <th scope="col">Subject</th>
                        <th scope="col">Ann. Type</th>
                        <th scope="col">Details</th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        publicAnnouncements.map((data, i) =>
                          <tr key={i}>
                            {/* <td data-toggle="tooltip" title={data.name}>{data.nse}</td> */}
                            <td>{data.name}</td>
                            <td style={{ maxWidth: '100px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', cursor: 'pointer' }} data-toggle="tooltip" title={data.subject}>{data.subject}</td>
                            {/* <td>{data && data.keyword ? data.keyword.toUpperCase() : "GENERAL"}</td> */}
                            <td>{data.keyword.toUpperCase()}</td>
                            <td style={{ maxWidth: '100px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', cursor: 'pointer' }} data-toggle="tooltip" title={data.narration}> <a href={data.url} target="_blank">{data.narration}</a></td>
                            {/* <td>{moment(data.date).format('DD-MM-YYYY')}</td> */}
                            <td> {timeDifference(Date.now(), data.date)}</td>
                          </tr>
                        )
                      }

                    </tbody>
                  </table>
                </div>

                <div className="paginati mt-3">
                  <div className="paginati_l"></div>
                  <div className="paginati_r">
                    <Stack spacing={2}>
                      <Pagination count={totalPageAnnouncement} shape="rounded" onChange={(e, value) => handleChangePageAnnouncement(e, value)} />
                    </Stack>
                  </div>
                </div>
              </div>

            </div>
            {/* <!-- End Recent Activity --> */}





            {/*<!-- News & Updates Traffic --> */}
            <div class="card">


              <div class="card-body">
                <h5 class="card-title">Curated Business News</h5>

                <div class="news autoheight">
                  {
                    businessNews.map((data, i) =>
                      <div class="post-item clearfix" key={i}>
                        <span>{timeDifference(Date.now(), data.date)}</span>
                        {/* <img src='https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png' width="50" alt="" /> */}
                        <h4><a href={data.link} target="_blank">{data.title}</a></h4>
                        <p style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', cursor: 'pointer' }} data-toggle="tooltip" title={data.summary}>{data.summary}</p>

                      </div>
                    )
                  }


                </div>

                <div className="paginati mt-3">
                  <div className="paginati_l"></div>
                  <div className="paginati_r">
                    <Stack spacing={2}>
                      <Pagination count={totalPageNews} shape="rounded" onChange={(e, value) => handleChangePageNews(e, value)} />
                    </Stack>
                  </div>
                </div>
                {/* <!-- End sidebar recent posts--> */}

              </div>
            </div>
            {/* <!-- End News & Updates --> */}

          </div>
          {/* <!-- End Right side columns --> */}

        </div>
      </section>
    </div>
  )
}
export default Dashboard