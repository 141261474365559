import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import {sign_up,login} from '../../service/service';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom'
export default function LoginModal(props) {
    const navigate = useNavigate();
    const [show, setShow] = React.useState(false);
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    const [username, setUsername] = React.useState('');
    const [passkey, setPasskey] = React.useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const [isLogin, setisLogin] = React.useState(false)

    const handleisLoginShow = () => setisLogin(true);
    const handleisLoginHide = () => setisLogin(false);
 
    const [checked, setChecked] = useState(false);

    var mailformat = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,6}$";
    const loginFun = () => {
        if(username==''){
            NotificationManager.error('Please enter username');
            return false
        }else if (!username.match(mailformat)) {
            NotificationManager.error('Please enter valid email');
            return false
        }
        if(passkey==''){
            NotificationManager.error('Please enter password');
            return false
        }
        let data = {
            "email": username,
            "password": passkey
        }
        console.log(data)
        
        login(data).then(result => {
            if (result.data.success) {
                // localStorage.setItem("user", JSON.stringify(result.data));
                localStorage.setItem("stockuser", JSON.stringify(result.data.userDetails));

                handleClose()
                window.location.reload(); 
                // navigate("/")
                // userEvent("login")
            } else {
                NotificationManager.error('Your username or password is incorrect');
            }


        })

    }
    const signUpFun = () => {
        if(checked==false){
            NotificationManager.error('Please agree terms and conditions');
            return false
        }
        if(email==''){
            NotificationManager.error('Please enter email');
            return false
        }else if (!email.match(mailformat)) {
            NotificationManager.error('Please enter valid email');
            return false
        }
        if(name==''){
            NotificationManager.error('Please enter name');
            return false
        }
        if(password==''){
            NotificationManager.error('Please enter password');
            return false
        }
        let data = {
            "email": email,
            "name": name,
            "password": password
        }
        console.log(data)
        
        sign_up(data).then(result => {
          
            if (result.data.success) {
                // localStorage.setItem("user", JSON.stringify(result.data));
                localStorage.setItem("stockuser", JSON.stringify(result.data.userDetails));

                handleClose()
                window.location.reload(); 
                // navigate("/")
                // userEvent("login")
            } else {
                NotificationManager.error('Email already exist');
            }


        })

    }
    return (
        <>
            <NotificationContainer />
            <span class="d-none d-md-block  ps-2" id='newClick' style={{ textDecoration: 'none' }} onClick={handleShow}>Login</span>
            <Modal show={show} backdrop="static"  onHide={handleClose}>
                <Modal.Body className='p-0'>
                    <div className='bg-white rounded-3 mb-5'>

                        <div className="row align-items-center">
                            <div className='col-sm-12 text-end' style={{ padding: '5px 20px' }}>
                                {/* <button type="button" onClick={handleClose} className="btn-close"></button> */}
                            </div>


                        </div>

                        <section style={!isLogin ? {} : { display: 'none' }}>
                            <div class="container">
                                <div class="row justify-content-center">
                                    <div class="col-lg-12 col-md-12 d-flex flex-column align-items-center justify-content-center">

                                        <div class="d-flex justify-content-center py-4">
                                            <a href="index.html" class="logo d-flex align-items-center w-auto">
                                                <img src="assets/img/WealthGame Logo Square.png" alt="" />
                                                <span class="d-none d-lg-block">WealthGame</span>
                                            </a>
                                        </div>
                                        <div class="card mb-3">

                                            <div class="card-body">

                                                <div class="pt-4 pb-2">
                                                    <h5 class="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                                                    <p class="text-center small">Enter your username & password to login</p>
                                                </div>

                                                <form class="row g-3 needs-validation" novalidate>

                                                    <div class="col-12">
                                                        <label for="yourUsername" class="form-label">Username</label>
                                                        <div class="input-group has-validation">
                                                            <span class="input-group-text" id="inputGroupPrepend">@</span>
                                                            <input type="text" name="username" class="form-control" id="yourUsername" value={username}  onChange={(e) => { setUsername(e.target.value);}} required />
                                                            <div class="invalid-feedback">Please enter your username.</div>
                                                        </div>
                                                    </div>

                                                    <div class="col-12">
                                                        <label for="yourPassword" class="form-label">Password</label>
                                                        <input type="password" name="password" class="form-control" id="yourPassword" value={passkey}  onChange={(e) => { setPasskey(e.target.value);}} required />
                                                        <div class="invalid-feedback">Please enter your password!</div>
                                                    </div>

                                                    <div class="col-12">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe" />
                                                            <label class="form-check-label" for="rememberMe">Remember me</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <a class="btn btn-primary w-100" onClick={loginFun}>Login</a>
                                                    </div>
                                                    <div class="col-12">
                                                        <p class="small mb-0">Don't have account? <a style={{cursor: 'pointer',color: '#0d6efd'}} onClick={handleisLoginShow}>Create an account</a></p>
                                                    </div>
                                                </form>

                                            </div>
                                        </div>
                                        {/* 
                                        <div class="credits">
                                             <a href="https://bootstrapmade.com/">BootstrapMade</a>
                                        </div> */}

                                    </div>
                                </div>
                            </div>

                        </section>
                        <section style={isLogin ? {} : { display: 'none' }}>
                            <div class="container">
                                <div class="row justify-content-center">
                                    <div class="col-lg-12 col-md-12 d-flex flex-column align-items-center justify-content-center">

                                        <div class="d-flex justify-content-center py-4">
                                            <a href="index.html" class="logo d-flex align-items-center w-auto">
                                                <img src="assets/img/WealthGame Logo Square.png" alt="" />
                                                    <span class="d-none d-lg-block">WealthGame</span>
                                            </a>
                                        </div>

                                        <div class="card mb-3">

                                            <div class="card-body">

                                                <div class="pt-4 pb-2">
                                                    <h5 class="card-title text-center pb-0 fs-4">Create an Account</h5>
                                                    <p class="text-center small">Enter your personal details to create account</p>
                                                </div>

                                                <form class="row g-3 needs-validation" novalidate>
                                                    <div class="col-12">
                                                        <label for="yourName" class="form-label">Your Name</label>
                                                        <input type="text" name="name" class="form-control" id="yourName" value={name}  onChange={(e) => { setName(e.target.value);}} required />
                                                            <div class="invalid-feedback">Please, enter your name!</div>
                                                    </div>

                                                    <div class="col-12">
                                                        <label for="yourEmail" class="form-label">Your Email</label>
                                                        <input type="email" name="email" class="form-control" id="yourEmail" value={email}  onChange={(e) => { setEmail(e.target.value);}} required />
                                                            <div class="invalid-feedback">Please enter a valid Email adddress!</div>
                                                    </div>

                                               

                                                    <div class="col-12">
                                                        <label for="signupPassword" class="form-label">Password</label>
                                                        <input type="password" name="password" class="form-control" id="signupPassword" value={password}  onChange={(e) => { setPassword(e.target.value);}} required />
                                                            <div class="invalid-feedback">Please enter your password!</div>
                                                    </div>

                                                    <div class="col-12">
                                                        <div class="form-check">
                                                            <input class="form-check-input" name="terms" type="checkbox" value="" id="acceptTerms" checked={checked}  onChange={e => setChecked(e.target.checked)} />
                                                                <label class="form-check-label" for="acceptTerms">I agree and accept the <a href="#">terms and conditions</a></label>
                                                                <div class="invalid-feedback">You must agree before submitting.</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <a class="btn btn-primary w-100" onClick={signUpFun}>Create Account</a>
                                                    </div>
                                                    <div class="col-12">
                                                        <p class="small mb-0">Already have an account? <a style={{cursor: 'pointer',color: '#0d6efd'}}  onClick={handleisLoginHide}>Log in</a></p>
                                                    </div>
                                                </form>

                                            </div>
                                        </div>
{/* 
                                        <div class="credits">
                                            <a href="https://bootstrapmade.com/">BootstrapMade</a>
                                        </div> */}

                                    </div>
                                </div>
                            </div>

                        </section>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

