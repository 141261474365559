import React, { useRef, useEffect } from "react";
import $ from "jquery";
import {
  getActionType,
  getInsiderBuySell,
  getInstrumentType,
  getTransactionBy,
  getBuySellType,
  getNewsKeyword,
  getPublicAnnouncements,
  getLatestEarning,
  getBusinessNews,
  getAllCompany,
  allInsiderCount,
  buySellAmount,
  getInsiderBulkCombinedTable,
  getStockPriceInvestor,
  getColorLineChart,
  getHitMapChart,
} from "../service/service";
import moment from "moment";
import Select from "react-select";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import DateRangePicker from "react-bootstrap-daterangepicker";
import ApexCharts from "apexcharts";
import { echarts } from "./echarts";
import ReactEcharts from "echarts-for-react";
import EchartsTree from "./echarts2";
import EchartsTree1 from "./echarts3";
import BasicFilterDemo from "./datatables";
const Insider = () => {
  const [sellList, setsellList] = React.useState([]);
  const [allCompany, setAllCompany] = React.useState([]);
  const [actionType, setactionType] = React.useState([]);
  const [instrumentType, setinstrumentType] = React.useState([]);
  const [transactionBy, settransactionBy] = React.useState([]);
  const [buySellType, setbuySellType] = React.useState([]);

  const [action_type, setaction_type] = React.useState([]);
  const [type_pre, settype_pre] = React.useState([]);
  const [entity_keyword, setentity_keyword] = React.useState([]);
  const [action_keyword, setaction_keyword] = React.useState([]);
  const [allCompany_keyword, setallCompany_keyword] = React.useState([]);

  const [totalPsector, setTotalPsector] = React.useState(0);
  const [limit, setLimit] = React.useState("20");
  const [offset, setOffset] = React.useState("0");
  const [dropSearch, setdropSearch] = React.useState("");

  const [insiderBuyCount, setinsiderBuyCount] = React.useState("");
  const [insiderSellCount, setinsiderSellCount] = React.useState("");
  const [insiderBuyAmountCount, setinsiderBuyAmountCount] = React.useState("");
  const [insiderSellAmountCount, setinsiderSellAmountCount] =
    React.useState("");
  const [promoterBuyCount, setpromoterBuyCount] = React.useState("");
  const [promoterSellCount, setpromoterSellCount] = React.useState("");
  const [promoterBuyAmountCount, setpromoterBuyAmountCount] =
    React.useState("");
  const [promoterSellAmountCount, setpromoterSellAmountCount] =
    React.useState("");

  const [dateRange, setdateRange] = React.useState("");
  const [candlestick, setcandlestick] = React.useState({});
  const [treeData, settreeData] = React.useState({});
  const [NiftyData, setNiftyData] = React.useState({});
  const [NiftyCptions, setNiftyCptions] = React.useState({});
  const [candlestickShow, setcandlestickShow] = React.useState(false);
  const allInsiderCountFun = () => {
    var allCompany = "";
    if (allCompany_keyword.value) {
      allCompany = allCompany_keyword.value;
    }
    let data = {
      allCompany: allCompany.toString(),
    };
    allInsiderCount(data).then((result) => {
      if (result.data.success) {
        setinsiderBuyCount(result.data.response.insiderBuyCount);
        setinsiderSellCount(result.data.response.insiderSellCount);
        setinsiderBuyAmountCount(result.data.response.insiderBuyAmountCount);
        setinsiderSellAmountCount(result.data.response.insiderSellAmountCount);

        setpromoterBuyCount(result.data.response.promoterBuyCount);
        setpromoterSellCount(result.data.response.promoterSellCount);
        setpromoterBuyAmountCount(result.data.response.promoterBuyAmountCount);
        setpromoterSellAmountCount(
          result.data.response.promoterSellAmountCount
        );
      }
    });
  };
  const objGroup = function (data) {
    let company = {}; // Create "company" object

    for (let arr of data) {
      // For each item in "arrs",

      const sector = arr.sector; // Get the sector of the arr
      const name = arr.nse_name; // Get the name of the arr

      if (sector in company) {
        // If the current sector is already a key in "company",
        // company[sector].push(name); // Push the arr's name to the array.
        company[sector][name] = arr.net_value;
      } else {
        // Otherwise (if the current sector is not yet a key in "company"),
        company[sector] = { [name]: arr.net_value }; // Set it to an array with the one name in it.
      }
    }
    return company;
  };
  const buySellAmountFun = () => {
    var allCompany = "";
    if (allCompany_keyword.value) {
      allCompany = allCompany_keyword.value;
    }
    let data = {
      allCompany: allCompany.toString(),
    };
    buySellAmount(data).then((result) => {
      if (result.data.success) {
        var response = result.data.response;
        // console.log(response)

        var array = [];
        for (let arr of response) {
          var obj = {
            x: arr.sector,
            y: arr.net_value.toLocaleString("en-IN", {
              maximumFractionDigits: 0,
            }),
          };
          array.push(obj);
        }
        var newData = objGroup(response);
        settreeData(newData);
        treeChart(array);
      }
    });
  };
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("/");
  }
  const getStockPriceInvestorFun = () => {
    var allCompany = "";
    setcandlestick([]);
    if (allCompany_keyword.value) {
      allCompany = allCompany_keyword.value;
    }
    let data = {
      allCompany: allCompany.toString(),
    };
    getStockPriceInvestor(data).then((result) => {
      if (result.data.success) {
        var response = result.data.response;
        var array = [];
        var newArr = [];
        for (let arr of response) {
          var obj = {
            x: new Date(arr.date),
            y: [arr.open, arr.high, arr.low, arr.close],
          };
          newArr.push([
            formatDate(new Date(arr.date)),
            arr.open,
            arr.close,
            arr.low,
            arr.high,
          ]);
          array.push(obj);
        }
        setTimeout(() => {
          if (newArr.length > 0) {
            var newOptions = echarts(newArr);
            setcandlestick(newOptions);
            setcandlestickShow(true);
          }
        }, 400);

        candlestickChart(array);
      }
    });
  };
  const getInsiderBulkCombinedTableFun = () => {
    var actionType = getArrVal(action_type);
    var entityKeyword = getArrVal(entity_keyword);
    var actionKeyword = getArrVal(action_keyword);
    var allCompany = "";
    if (allCompany_keyword.value) {
      allCompany = allCompany_keyword.value;
    }

    let data = {
      transactionType: actionType,
      // "instrumentType": typePre,
      dateRange: dateRange,
      investor: entityKeyword,
      investorGroup: actionKeyword,
      allCompany: allCompany.toString(),
    };

    getInsiderBulkCombinedTable(data).then((result) => {
      if (result.data.success) {
        // var response = result.data.response
        if (result.data.response.length > 0) {
          setsellList(result.data.response);
          const unique = [
            ...new Set(result.data.response.map((item) => item.entity)),
          ];
          var newArr = [];
          for (let arr of unique) {
            newArr.push({ value: arr, label: arr });
          }

          setbuySellType(newArr);
        } else {
          setsellList([]);
        }
      }
    });
  };

  const showCompany = () => {
    let data = {
      search: dropSearch,
    };
    getAllCompany(data).then((result) => {
      if (result.data.success) {
        var res = result.data.response;
        for (let arr of res) {
          arr.value = arr.mapper_id;
          arr.label = arr.name + " (" + arr.nse + ")";
        }
        setAllCompany(res);
      }
    });
  };
  useEffect(() => {
    showCompany();
  }, [dropSearch]);

  const handleInputChange = (value) => {
    if (value.length >= 2) {
      setdropSearch(value);
    } else {
      setAllCompany([]);
    }
  };
  const showActionType = () => {
    let data = {};
    getActionType(data).then((result) => {
      if (result.data.success) {
        var res = result.data.response;
        for (let arr of res) {
          arr.value = arr.action_type;
          arr.label = arr.action_type;
        }
        setactionType(res);
      }
    });
  };
  const showInstrumentType = () => {
    let data = {};
    getInstrumentType(data).then((result) => {
      if (result.data.success) {
        var res = result.data.response;
        for (let arr of res) {
          arr.value = arr.type_pre;
          arr.label = arr.type_pre;
        }

        setinstrumentType(res);
      }
    });
  };
  const showTransactionBy = () => {
    let data = {};
    getTransactionBy(data).then((result) => {
      if (result.data.success) {
        var res = result.data.response;
        for (let arr of res) {
          arr.value = arr.entity_keyword;
          arr.label = arr.entity_keyword;
        }
        settransactionBy(res);
      }
    });
  };
  const showGetBuySellType = () => {
    let data = {};

    getBuySellType(data).then((result) => {
      if (result.data.success) {
        var res = result.data.response;
        for (let arr of res) {
          arr.value = arr.action_keyword;
          arr.label = arr.action_keyword;
        }

        setactionType(res);
      }
    });
  };
  

  function getArrVal(obj) {
    var newArr = [];
    for (let arr of obj) {
      newArr.push(arr.value);
    }
    return newArr;
  }
  const InsiderBuySell = () => {
    var actionType = getArrVal(action_type);
    // var typePre = getArrVal(type_pre)
    var entityKeyword = getArrVal(entity_keyword);
    var actionKeyword = getArrVal(action_keyword);
    var allCompany = "";
    if (allCompany_keyword.value) {
      allCompany = allCompany_keyword.value;
    }

    let data = {
      limit: limit,
      start: offset,
      transactionType: actionType,
      // "instrumentType": typePre,
      transactionBy: entityKeyword,
      buySellType: actionKeyword,
      allCompany: allCompany.toString(),
    };
    getInsiderBuySell(data).then((result) => {
      if (result.data.success) {
      }
    });
  };
  useEffect(() => {
    getInsiderBulkCombinedTableFun();
  }, [
    limit,
    offset,
    dateRange,
    action_type,
    entity_keyword,
    action_keyword,
    allCompany_keyword,
  ]);

  useEffect(() => {
    allInsiderCountFun();
    buySellAmountFun();
    getInsiderBulkCombinedTableFun();
    getStockPriceInvestorFun();
  }, [allCompany_keyword]);
  function handlesetActionType(data) {
    setaction_type(data);
  }
  const handleChangePsector = (e, val) => {
    let offeset = (val - 1) * limit;
    setOffset(offeset);
  };
  function handlesetCompany(data) {
    setaction_keyword("");
    setentity_keyword("");
    settype_pre("");
    setdateRange("");
    setallCompany_keyword(data);
  }
  function handlesetTypePre(data) {
    settype_pre(data);
  }

  function handlesetEntityKeyword(data) {
    setentity_keyword(data);
  }
  function handlessetActionKeyword(data) {
    setaction_keyword(data);
  }
  const treeChart = (data) => {
    var options = {
      series: [
        {
          data: data,
        },
      ],
      legend: {
        show: true,
      },
      chart: {
        height: 450,
        type: "treemap",
      },
      title: {
        // text: 'Aggregated market buy or sell by all participants'
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "30px",
        },
        formatter: function (text, op) {
          return [text, op.value];
        },
        offsetY: -4,
      },
      plotOptions: {
        treemap: {
          enableShades: true,
          radius: 10,
          shadeIntensity: 0.1,
          reverseNegativeShade: true,
          colorScale: {
            ranges: [
              {
                from: -4000,
                to: 0,
                color: "#BB968A",
              },
              {
                from: -40000,
                to: -8000,
                color: "#B23D6D",
              },
              {
                from: -8000,
                to: -4000,
                color: "#2A607C",
              },
              {
                from: 40,
                to: 4000,
                color: "#F5CF23",
              },
              {
                from: 0,
                to: 40,
                color: "#DAB47D",
              },
            ],
          },
        },
      },
    };
    document.querySelector("#reportsChart").innerHTML = "";
    var chart = new ApexCharts(
      document.querySelector("#reportsChart"),
      options
    );
    chart.render();
  };
  const candlestickChart = (data) => {
    var options = {
      series: [
        {
          data: data,
        },
      ],
      chart: {
        type: "candlestick",
        height: 450,
        id: "candles",
        toolbar: {
          autoSelected: "pan",
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        candlestick: {
          colors: {
            upward: "#3C90EB",
            downward: "#DF7D46",
          },
        },
      },
      xaxis: {
        type: "datetime",
      },
    };
    document.querySelector("#candlestickChart").innerHTML = "";
    var chart = new ApexCharts(
      document.querySelector("#candlestickChart"),
      options
    );
    chart.render();
  };
  
  useEffect(() => {
    // showActionType()
    showGetBuySellType();
    showInstrumentType();
    showTransactionBy();
    // showgetHitMapChart()
    // showGetBuySellType()

    //  colorlineChart()
  }, []);
  function numDifferentiation(val) {
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(2) + " Cr";
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(2) + " Lac";
    }
    /*else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';*/
    return val;
  }
  function handleEvent(event, picker) { }
  function handleCallback(event, picker) {
    var dateRange =
      "'" +
      moment(picker.startDate).format("YYYY-MM-DD") +
      " 00:00:00' and '" +
      moment(picker.endDate).format("YYYY-MM-DD") +
      " 23:59:59'";
    setdateRange(dateRange);
  }
  return (
    <div>
      <div class="psectortitle row">
        <div className="col-sm-9">
          <h1>Insider</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a>Home</a>
              </li>
              <li class="breadcrumb-item active">Insider</li>
            </ol>
          </nav>
        </div>

        <div className="col-sm-3">
          <Select
            options={allCompany}
            placeholder="Company Name"
            value={allCompany_keyword}
            onInputChange={handleInputChange}
            onChange={handlesetCompany}
            isSearchable={true}
          // isMulti
          />
        </div>
      </div>
      <section class="section dashboard">
        <div class="row">
          <div class="col-xxl-3 col-md-6">
            <div class="card info-card revenue-card">
              <div class="card-body">
                <h5 class="card-title">
                  Number of Insider Buying <span>| Last 90 Days</span>
                </h5>

                <div class="d-flex align-items-center">
                  <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i class="bi bi-company"></i>
                  </div>
                  <div class="ps-3">
                    <h6>{insiderBuyCount}</h6>
                    {/* <span class="text-success small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">increase</span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xxl-3 col-md-6">
            <div class="card info-card customers-card">
              <div class="card-body">
                <h5 class="card-title">
                  Number of Insider Selling <span>| Last 90 Days</span>
                </h5>

                <div class="d-flex align-items-center">
                  <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i class="bi bi-company"></i>
                  </div>
                  <div class="ps-3">
                    <h6>{insiderSellCount}</h6>
                    {/* <span class="text-success small pt-1 fw-bold">8%</span> <span class="text-muted small pt-2 ps-1">increase</span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xxl-3 col-xl-12">
            <div class="card info-card revenue-card">
              <div class="card-body">
                <h5 class="card-title">
                  Amount of Insider Buying<span>| Last 90 Days</span>
                </h5>

                <div class="d-flex align-items-center">
                  <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i class="bi bi-currency-rupee"></i>
                  </div>
                  <div class="ps-3">
                    <h6>
                      {(insiderBuyAmountCount / 1000000).toLocaleString(
                        "en-IN",
                        { maximumFractionDigits: 0 }
                      )}
                      <span class="text-muted small pt-2 ps-1">INR mn</span>
                    </h6>
                    {/* <span class="text-danger small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">decrease</span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xxl-3 col-xl-12">
            <div class="card info-card customers-card">
              <div class="card-body">
                <h5 class="card-title">
                  Amount of Insider Selling <span>| Last 90 Days</span>
                </h5>

                <div class="d-flex align-items-center">
                  <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i class="bi bi-currency-rupee"></i>
                  </div>
                  <div class="ps-3">
                    <h6>
                      {(insiderSellAmountCount / 1000000).toLocaleString(
                        "en-IN",
                        { maximumFractionDigits: 0 }
                      )}
                      <span class="text-muted small pt-2 ps-1">INR mn</span>
                    </h6>
                    {/* <span class="text-success small pt-1 fw-bold">(in INR mn)</span>  */}
                    {/* <span class="text-danger small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">decrease</span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xxl-3 col-xl-12">
            <div class="card info-card revenue-card">
              <div class="card-body">
                <h5 class="card-title">
                  Number of Promoter Buying <span>| Last 90 Days</span>
                </h5>

                <div class="d-flex align-items-center">
                  <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i class="bi bi-company"></i>
                  </div>
                  <div class="ps-3">
                    <h6>{promoterBuyCount}</h6>
                    {/* <span class="text-danger small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">decrease</span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xxl-3 col-xl-12">
            <div class="card info-card customers-card">
              <div class="card-body">
                <h5 class="card-title">
                  Number of Promoter Selling <span>| Last 90 Days</span>
                </h5>

                <div class="d-flex align-items-center">
                  <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i class="bi bi-company"></i>
                  </div>
                  <div class="ps-3">
                    <h6>{promoterSellCount}</h6>
                    {/* <span class="text-danger small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">decrease</span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xxl-3 col-xl-12">
            <div class="card info-card revenue-card">
              <div class="card-body">
                <h5 class="card-title">
                  Amount of Promoter Buying <span>| Last 90 Days</span>
                </h5>

                <div class="d-flex align-items-center">
                  <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i class="bi bi-currency-rupee"></i>
                  </div>
                  <div class="ps-3">
                    <h6>
                      {(promoterBuyAmountCount / 1000000).toLocaleString(
                        "en-IN",
                        { maximumFractionDigits: 0 }
                      )}
                      <span class="text-muted small pt-2 ps-1">INR mn</span>
                    </h6>
                    {/* <span class="text-danger small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">decrease</span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xxl-3 col-xl-12">
            <div class="card info-card customers-card">
              <div class="card-body">
                <h5 class="card-title">
                  Amount of Promoter Selling <span>| Last 90 Days</span>
                </h5>

                <div class="d-flex align-items-center">
                  <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i class="bi bi-currency-rupee"></i>
                  </div>
                  <div class="ps-3">
                    <h6>
                      {(promoterSellAmountCount / 1000000).toLocaleString(
                        "en-IN",
                        { maximumFractionDigits: 0 }
                      )}
                      <span class="text-muted small pt-2 ps-1">INR mn</span>
                    </h6>
                    {/* <span class="text-danger small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">decrease</span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">
                    Sector Wise Sell Amount in INR mn (Last 90 Days)
                  </h5>

                  {/* <!-- Line Chart --> */}
                  {/* <div id="reportsChart"></div> */}

                  <EchartsTree />
                  <h5 class="card-title">
                    Sector Wise Buy Amount in INR mn (Last 90 Days)
                  </h5>
                  <EchartsTree1 />
                  {/* <!-- End Line Chart --> */}
                </div>
              </div>
            </div>
          </div>
         
          <>
            {allCompany_keyword.value ? (
              <div>
                <div class="col-lg-12">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">
                          Stock Price Movement{" "}
                          <span>with important events</span>
                        </h5>

                        {/* <!-- Line Chart --> */}
                        {/* <div id="candlestickChart"></div> */}
                        <div className="col-sm-12">
                          {candlestickShow ? (
                            <ReactEcharts
                              option={candlestick}
                              style={{ height: "400px", width: "100%" }}
                            />
                          ) : null}
                          {/* <Echarts data={candlestick} /> */}
                        </div>

                        {/* <!-- End Line Chart --> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">Filters </h5>
                        <div className="row">
                          <div className="col-sm-3">
                            <div className="">
                              <DateRangePicker
                                onEvent={handleEvent}
                                onApply={handleCallback}
                              >
                                <input className="form-control" />
                              </DateRangePicker>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="">
                              <Select
                                options={actionType}
                                placeholder="Transaction type"
                                value={action_type}
                                onChange={handlesetActionType}
                                isSearchable={true}
                                isMulti
                              />
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="">
                              <Select
                                options={buySellType}
                                placeholder="Investor Group"
                                value={action_keyword}
                                onChange={handlessetActionKeyword}
                                isSearchable={true}
                                isMulti
                              />
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="">
                              <Select
                                options={transactionBy}
                                placeholder="Investor"
                                value={entity_keyword}
                                onChange={handlesetEntityKeyword}
                                isSearchable={true}
                                isMulti
                              />
                            </div>
                          </div>
                          <div className="col-sm-2"></div>
                          <div className="col-sm-2"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="card recent-sales overflow-auto">
                      <div class="card-body">
                        <h5 class="card-title">Insider Buy Sell</h5>

                        <table class="table datatable">
                          <thead>
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Date</th>
                              <th scope="col">Investor</th>
                              <th scope="col">Investor Type </th>
                              <th scope="col">Transaction Type</th>
                              <th scope="col">Deal Type</th>
                              <th scope="col">Quantity</th>
                              <th scope="col">Value</th>
                              <th scope="col">Avg Traded Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {sellList.length > 0
                              ? sellList.map((data, i) => (
                                <tr key={i}>
                                  <td
                                    style={{
                                      maxWidth: "100px",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {data.name}
                                  </td>
                                  <td>
                                    {moment(data.date).format("DD-MM-YYYY")}
                                  </td>
                                  <td>{data.entity}</td>
                                  <td>{data.entity_keyword}</td>
                                  <td>{data.action_keyword}</td>
                                  <td>{data.deal_type}</td>
                                  <td>{data.qty}</td>
                                  <td>{numDifferentiation(data.amount)}</td>
                                  <td>{data.price.toFixed(0)}</td>
                                </tr>
                              ))
                              : null}
                          </tbody>
                        </table>

                        {/* <div className="paginati">
                                        <div className="paginati_l"></div>
                                        <div className="paginati_r">
                                            <Stack spacing={2}>
                                                <Pagination count={totalPsector} shape="rounded" onChange={(e, value) => handleChangePsector(e, value)} />
                                            </Stack>
                                        </div>
                                    </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        </div>
      </section>
    </div>
  );
};
export default Insider;
